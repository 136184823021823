html {
	background-color:#FFF;
	-webkit-font-smoothing:antialiased
}

@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91d701923a38fff803bf_Kastelov%20-%20Axiforma%20Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91f4d48576f76252cf56_Kastelov%20-%20Axiforma%20ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef91e3a081fc8635824020_Kastelov%20-%20Axiforma%20Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef920b3d8f860105f8fb52_Kastelov%20-%20Axiforma%20Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef921d47d2824567027b9a_Kastelov%20-%20Axiforma%20Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef929d9dfbe2a6fd71b242_Kastelov%20-%20Axiforma%20SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Axiforma';
  src: url('https://uploads-ssl.webflow.com/5fef53698f7605f5423fd2d7/5fef92bc3d451acbfbacab99_Kastelov%20-%20Axiforma%20Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  font-family:'Axiforma';
}

/* HOME PAGE */

.hero_container {
	color:#FFF;
	position:absolute;
	left:0px;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    transition:all 1s ease;
	backdrop-filter:blur(0px);
	-webkit-backdrop-filter:blur(0px);
	border-right:rgba(255,255,255,0) solid 1px;
}

.hero_container.on {
	background:rgba(255,255,255,.15);
	backdrop-filter:blur(30px);
	-webkit-backdrop-filter:blur(30px);
	border-right:rgba(255,255,255,1) solid 1px;
}

.hero_content {
	width:30vw;
	padding:60px;
	margin-bottom:60px;
}

.hero_content h1 {
	font-size:45px;
	line-height: 57px;
	font-weight:600;
	padding:0px;
	margin:0px;
}

.hero_content h2 {
	font-size:17px;
	line-height: 27px;
	font-weight:300;
	padding:0px;
	margin:10px 0px 0px;
}

.logo {
	width:400px;
	height:40px;
	background-image:url("../images/logo.svg");
	background-repeat: no-repeat;
	background-size:contain;
	margin-bottom:60px;
}

/* VIEWPORT */

.mountButton {
	position:absolute;
	top:80px;
	right:3px;
	z-index:2
}

.testing {
	display:none;
}

.centerCamera {
	color:#FFF;
	position:absolute;
	right:10px;
	top:10px;
	font-weight:400;
	z-index:2;
	font-size:14px;
	cursor:pointer;
	opacity:0;
	transition:opacity .3s ease 0s;
}

.centerCamera.visible {
	opacity:1;
	transition:opacity .3s ease .5s;
}

.testing_data {
	position:absolute;
	left:50%;
	color:#000;
	z-index:3;
}

.cssRenderer {
	position:absolute;
	top:0px;
	z-index:1;
}

.viewportContainer {
	display:flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-image: radial-gradient(circle farthest-side at 10% -10%, rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0) 55%), radial-gradient(circle at 100% 0%, rgba(173, 153, 232, 0.96) 17%, rgba(173, 153, 232, 0) 69%), radial-gradient(circle at -10% 80%, rgb(171, 98, 211) 15%, rgba(219, 187, 236, 0.37) 74%, rgba(255, 255, 255, 0) 98%), linear-gradient( 153deg, rgb(243, 236, 250), rgb(144, 103, 224) 40%, rgb(63, 81, 246) 88%);
	background-image: linear-gradient(180deg, #35383a 0%, #0b0c0d 100%)
}

.viewport {
	width:100vw;
	height:100vh;
	opacity:1;
}

canvas {
	filter: brightness(100%) blur(0px);
	transition: filter .2s ease .6s;
}

.viewport.darken canvas {
	filter: brightness(90%) blur(5px);
	transition: filter .2s ease .6s;
}

.mobile canvas {
	transition: filter 1s ease 0s;
}
.mobile .viewport.darken canvas {
	filter: brightness(90%) blur(2px);
	transition: filter 1s ease 0s;
}

.progress {
	height:5px;
	width:300px;
	position:absolute;
	top:50%;
	margin:auto;
	background:#FFF;
	border-radius:3px;
	display:flex;
	justify-content: flex-start;
	align-content: stretch;
	transition:opacity .5s ease;
	border:#FFF solid 1px;
	z-index:3;
	
/* 	display:none; */
}

.progress div {
	background-image: linear-gradient(146deg, rgba(193, 76, 255, 1) 0%, rgba(83,45,222,1) 100%);
	display:block;
	transition:width 1s ease,transform .2s ease, opacity 0s ease .3s;
	transform-origin: left;
	width:100%;
	border-radius:3px;
}

.progress_loader {
	position:absolute;
	top:40%;
	margin:auto;
	z-index:10;
	opacity:1;
	transition:opacity .3s ease;
}

.animatedLoader {
	position:absolute;
	top:45%;
	margin:auto;
	z-index:10;
	opacity:1;
	transition:opacity .6s ease,transform 1s ease-in;
	color:#FFF;
	font-weight:300;
	font-size:24px;
	/* animation: animatedLoader-complete .6s ease-in forwards 1.5s; */
}

.animatedLoaderSpinner {
	opacity:0;
	transition:opacity .4s ease;
}

.animatedLoaderSpinner.active {
	opacity:0;
}

.animatedLoader span {
	animation: animatedLoader-anim 1.2s cubic-bezier(.4, 2, .5, 1) forwards;
	position:relative;
	display:inline-block;
	opacity:0;

	background-image: -webkit-linear-gradient(45deg, #8b3dd7, #9842d7);
	-webkit-background-clip: text;
	background-color: rgb(0, 0, 0);	
	-webkit-text-fill-color: transparent;
}

.animatedLoader span:nth-child(2) {
	background-image: -webkit-linear-gradient(45deg, #ac42f2, #c446fa);
	animation-delay:.1s;
}
.animatedLoader span:nth-child(3) {
	background-image: -webkit-linear-gradient(45deg, #c84bfd, #e54dfc);
	animation-delay:.2s;
}
.animatedLoader span:nth-child(4) {
	background-image: -webkit-linear-gradient(45deg, #f750fa, #ff37f5);
	animation-delay:.3s;
	font-weight:600;
}

.animatedLoader-complete {
	opacity:0;
	transform:scale(.7);
}

@keyframes animatedLoader-anim {
    0% {
        transform:translateY(13px);
		opacity:0;
		filter:blur(5px);
    }
    100% {
        transform:translateY(0px);
		opacity:1;
		filter:blur(0)
    }
}


/* LABELS */

.labelContainer {
/*
	width:0px;
	height:0px;
*/
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position:relative;
	color:#FFF;
/* 	transition:all 3s ease; */
}
@keyframes float-label {
    0% {
        left:0px;
        top:0px;
    }
    25% {
        left:10px;
        top:10px;
    }
    50% {
        left:-10px;
        top:10px;
    }
    50% {
        left:-10px;
        top:10px;
    }
    100% {
        left:0px;
        top:0px;
    }
}

.label {

	width:560px;
	min-height:100px;
	max-height:100px;
	font-size:46px;
/* 	background-image: linear-gradient(45deg, rgba(180,118,227,0.57) 0%, rgba(133, 92, 244, 0.54) 100%); */
	border-radius:10px;
	padding:0px;
	display:flex;
	justify-content: stretch;
	flex-direction: column;
	align-items:stretch;
	transition:border .7s ease-in-out,box-shadow .5s ease-in-out, height .7s ease-in-out,max-height .7s ease-in-out,transform .5s ease 0s,padding .7s ease .3s,width .7s ease,background .7s ease;
	cursor:pointer;
	position:absolute;
	top:-75px;
	text-align:left;
/* 	border:rgba(255, 255, 255, 1) solid 1px; */
/* 	border-left:rgba(255, 110, 222, 0.2) solid 2px; */
/* 	border-bottom:rgba(83, 46, 207, 0.2) solid 2px; */
	box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset;
	transform:scale(1);
	opacity:0;
	animation: start-label 0.6s cubic-bezier(.4, 1.5, .5, 1) forwards;
	color:#FFF;
	transform:scale(1.15);
	background-color:rgba(255,255,255,.9);
	color:#000;
	backdrop-filter:blur(15px);
	-webkit-backdrop-filter:blur(15px);
	will-change: transform, opacity;
	transform-style: preserve-3d;
	overflow:hidden;
}

/* .label:after {
	display:block;
	content:"";
	position:absolute;
	left:0px;
	right:0px;
	bottom:0px;
	top:0px;
	z-index:10;
	pointer-events: none;
	animation:label-pulse s 3s step-end infinite;
	border-radius:5px;
	box-shadow:0px 0px 0px 5px transparent;
} */

.labelContainer.close .label {
	transform:scale(0);
	transition: all .3s ease;
}

.mobile .label {
	animation: start-label-mobile 0.6s cubic-bezier(.4, 1.5, .5, 1) forwards;
	transform:scale(1.3);
	will-change: transform, opacity;
}

@keyframes start-label {
    0% {
        transform:scale(0);
		opacity:0;
    }
    100% {
        transform:scale(1.15);
		opacity:1;
    }
}
@keyframes start-label-mobile {
    0% {
        transform:scale(0);
		opacity:0;
    }
    100% {
        transform:scale(1.3);
		opacity:1;
    }
}
/* @keyframes label-pulse {
    0% {
		box-shadow:0px 0px 0px 5px transparent;
    }
	20% {
		box-shadow:0px 0px 0px 5px #ff22b5;
    }
    40% {
		box-shadow:0px 0px 0px 5px transparent;
    }
	60% {
		box-shadow:0px 0px 0px 5px transparent;
    }
} */

@keyframes label-pulse {
    0% {
		background-color:rgba(255,255,255,.9);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px transparent;
    }
	5% {
		background-color:rgba(255,255,255,1);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px #ff22b5;
    }
    10% {
		background-color:rgba(255,255,255,.9);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px transparent;
    }
    20% {
		background-color:rgba(255,255,255,.9);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px transparent;
    }
	25% {
		background-color:rgba(255,255,255,1);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px #ff22b5;
    }
    30% {
		background-color:rgba(255,255,255,.9);
		box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px transparent;
    }
}

.label:hover {
	background-color:rgba(255,255,255,1);

	box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset,0px 0px 0px 5px #ff22b5;
}

.labelContainer.open .label {
	max-height:700px;
	width:650px;
	cursor:default;
	background-color:rgba(255,255,255,1);
	transform:scale(1.15);
	box-shadow:15px 15px 70px rgba(0,0,0,.1), -1px 1px 0px 1px rgba(255,255,255,1) inset;
	will-change: transform, opacity;
	overflow:hidden;
	animation:none !important;
}

.mobile .labelContainer.open .label {
    transform:scale(1);
}

.labelContainer.close .label {
	transform:scale(0) !important;
}

.labelTitle {
	height:100px;
	width:100%;
	display:flex;
	justify-content: flex-start;
	align-items: center;
	font-size:31px;
	padding:3px 30px 0px;
	text-align:left;    
	background-color:rgba(255,255,255,0);
	border-radius:5px;
	transform:scale(1);
	flex-shrink: 0;
	position:relative;
	z-index:1;
	color:#FFF;
	border-radius:8px;
/* 	transition:border .7s ease, height 1s ease, font-size 1s ease; */
	transition:all .7s ease, height 1s ease;
	color:#000;
	cursor:pointer;
	will-change: transform, opacity;
}

.labelContainer.open .label .labelTitle {
	background-color:rgba(255,255,255,0);
	height:40px;
	border-radius:8px 8px 3px 3px;
	padding:6px 30px 0px;
	width:90.5%;
	color:#000;
}

.label .labelTitle span {
	transform:scale(1);
	transform-origin: top left;
	transition:transform 1s ease,font-size 1s ease;
	color:#000;
	background-color:#000;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight:600;
	font-size:33px;
}

.mobile .labelTitle span {
	font-weight:600;
	font-size:33px;
}

.labelContainer.open .label .labelTitle span {
	transform:scale(.9) translateY(34px) translateX(10px);
}

.mobile .labelContainer.open .label .labelTitle span {
	transform:scale(.9) translateY(34px) translateX(10px);
	/* font-size:30px; */
}

.labelButtonContainer {
	position:absolute;
	z-index:1;
	height:0px;
	box-shadow:none;
	display: flex;
    justify-content: center;
    align-items: center;
    top:0px;
	visibility:hidden;
}

.labelButton {
	height:90px;
	border-radius:5px;
	display:flex;
	justify-content: center;
	align-items: center;
	color:#FFF;
	font-size:24px;
	box-shadow:0px 0px 0px 2px rgba(255,255,255,1) inset,0px 0px 20px rgba(0,0,0,.3);
	transition:box-shadow .2s ease,transform .5s ease 0s;
	transform:scale(0);
	padding:2px 50px 0px;
	font-weight:600;
	margin: -75px 0px 0px;
/* 	background-color:rgba(255,255,255,1); */
	background-image: linear-gradient(180deg, rgba(87,87,87,1) 2.32%, rgba(15,15,15,1) 100%);
	
}

.labelButton:hover {
	cursor:pointer;
	box-shadow:0px 0px 0px 2px rgba(255,255,255,1) inset,0px 0px 10px rgba(0,0,0,.3);
}

.labelButton.open {
	transform:scale(1);
	transition:box-shadow .2s ease,transform .5s ease .8s;
}

/* LABEL LAYOUTS */

.labelContent {
	height:auto;
	overflow:hidden;
	display:flex;
	flex-direction: column;
	width:650px;
	opacity:0;
	transition:opacity 1s ease .1s;
	font-size:21px;
	line-height:34px;
	font-weight:300;
	color:#555;
	-WebKit-transform-style: preserve-3d
}

.labelContainer.open .label .labelContent {
	opacity:1;
}

.labelContent .buttonPad {
	padding-bottom:60px !important;
}

.labelContent .labelGraphic {
	height:200px;
	width:100%;
	margin:0px auto 20px;
	flex-shrink:0;
	background-size:contain;
	background-position:center;
	background-repeat:no-repeat;
}

.labelContent .labelGraphic video {
	width:100%;
	height:100%;
}

.labelContent .labelImg {
	width:40%;
	height:auto;
	margin:40px 25px 20px;
	float:right;
	position:relative;
	clear:left;
	border-radius:5px;
}

/* LABEL SPLIT */

.labelContent .labelTabs {
	padding:55px 14px 2px;
	margin:0px 30px 0px;
	position:relative;
	font-weight:300;
	display:flex;
	flex-direction: row;
	justify-content: space-between;
}

.labelContent .labelTabs:after {
	height:2px;
	width:50%;
	background:rgb(103, 0, 155);
	bottom:-3px;
	left:0px;
	border-radius:3px;
	display:block;
	content:'';
	position:absolute;
	transition:left .6s ease;
	opacity:.4;
}

.labelContent h3 {
	color:#000;
	padding:0px;
	margin:25px 0px 0px;
	font-size:42px;
	line-height:55px;
}

.labelContent p {
	margin:10px 0px 15px;
}

.labelContent strong {
	color:#000;
}

.labelContent .labelTabs div {
	flex-basis: 47%;
	cursor:pointer;
	margin:0px 0px 10px 0px;
	line-height:34px;
	font-size:23px;
	opacity:.5;
	transition:opacity .3s ease;
	display:flex;
	align-items: flex-end;
	font-weight:600;
	color:#000;
}

.labelContent .labelTabs div:hover {
	opacity:1;
}

.labelContent.left .labelTabs div:first-of-type,
.labelContent.right .labelTabs div:last-of-type {
	opacity:1
}

.labelContent.right .labelTabs:after {
	left:50% !important;
}

.labelContent .labelSplit {
	padding:40px 0px 50px;
	margin:0px 44px 0px;
	position:relative;
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	overflow:hidden;
}

.labelContent .labelSplit-inner {
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position:relative;
	left:0%;
/* 	height:100%; */
	min-width:100%;
	transition:left .6s ease;
}

.labelContent.right .labelSplit-inner {
	left:-100% !important;
}

.labelContent .labelSplit-block {
	transition:opacity .3s ease;
	width:100%;
	flex-shrink:0;
	overflow:scroll !important;
	overflow-scrolling: auto;
	-webkit-overflow-scrolling: touch;
}


/* LABEL CHECKBOX */

.labelContent .labelCheckbox {
	padding:20px 40px;
}

.checkboxContainer {
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	margin:20px 0px 20px
}

.checkbox {
	background-color:#e6a9f6;
	color:#FFF;
	font-weight:600;
	font-size:17px;
	padding:14px 30px 11px;
	border-radius:10px;
	display:flex;
	align-items: center;
	justify-content: center;
	cursor:pointer;
	white-space: nowrap;
	margin:7px 5px;
	transition:background .3s ease, box-shadow .3s ease;
	box-shadow:0px 0px 0px rgba(0,0,0,0);
    transform:scale(.97);
	animation: checkbox-click-reverse 0.5s cubic-bezier(.4, 1.5, .5, 1) forwards;
}

.checkbox.color0 {
	background-color:rgba(200, 129, 219, 0.77);
}

.checkbox.on.color0,
.checkbox.color0:hover {
	background-color:rgba(200, 129, 219, 1);
}

.checkbox.color1 {
	background-color:rgba(172, 118, 227, 0.79)
}

.checkbox.on.color1,
.checkbox.color1:hover {
	background-color:#a975e0
}

.checkbox.color2 {
	background-color:rgba(140, 117, 223, .77);
}

.checkbox.on.color2,
.checkbox.color2:hover {
	background-color:rgba(140, 117, 223, 1);
}

.checkbox.on {
	animation: checkbox-click 0.5s cubic-bezier(.4, 1.5, .5, 1) forwards;
	box-shadow:0px 0px 30px rgba(0,0,0,.2);
}

@keyframes checkbox-click {
    0% {
        transform:scale(.97);
    }
    100% {
        transform:scale(1.03);
    }
}

@keyframes checkbox-click-reverse {
    0% {
        transform:scale(1.03);
    }
    100% {
        transform:scale(.97);
    }
}

/* LABEL ARTICLE */

.labelContent .labelArticle {
	margin:25px 40px;
	overflow:auto;
	
}

.labelContent .labelArticle.buttonPad {
	margin-bottom:60px;
	padding:0px !important;
}



/* SCREEN */

.screen {
	width:1834px;
	height:1030px;
/* 	border:4px dashed rgba(255, 101, 247, 1); */
	opacity:0;
	transition:opacity .5s ease;
	color:rgba(255, 101, 247, 1);
	display:flex;
	justify-content: center;
	align-items: center;
	font-size:54px;
	text-align: center;
	backface-visibility: hidden;
/* 	backdrop-filter:blur(0px); */
/* 	-webkit-backdrop-filter:blur(0px); */
	display:none;

}

.screen .dropzone {
	width: 100%;
    height: 100%;
	border:5px dashed rgba(255,255,255, 0);
	transition:all .5s ease;
	background:rgba(0,0,0,0);
}

.screen .dropzone.active,
.screen .dropzone:hover {
	border:5px dashed rgba(255,255,255, 1);
/* 	background:rgba(0,0,0,.7); */
	cursor:pointer;
}

.screen.active {
	display:flex;
	opacity:1;
}

.screen.active:hover {
	opacity:1;
/* 	backdrop-filter:blur(10px); */
/* 	-webkit-backdrop-filter:blur(10px); */
}

/* editPoint */

.editPointContainer {
	position:absolute;
	width:100%;
	height:100%;
	z-index:10;
	pointer-events: none;
	opacity:0;
	transition:opacity .7s ease 0s;
	display:flex;
	justify-content: center;
	align-items: center;
}

.editPointBoundary {
	width:60%;
	height:50vw;
	margin:auto;
	position:relative;
}

.editPointContainer.active {
	opacity:1;
/* 	transition-delay:1s; */
}

/*
.theaterMode .editPointContainer.active  {
	transition-delay:0s !important;
}
*/

.editPoint {
	position:absolute;
	transform:scale(0);
	opacity:1;
	transition:all .5s ease 0s;
}

.editPointContainer.active .editPoint {
	transform:scale(1);
	opacity:1;
	transition:all .7s ease 0s;
}

.editPointContainer.active .editPoint.hide {
	transform:scale(0);
	opacity:0;
	transition:all .5s ease 0s;
}

.beacon {
	background:#FFF;
	width:20px;
	height:20px;
	display:flex;
	justify-content: center;
	align-items: center;
	border-radius:100%;
	position:absolute;
	pointer-events: auto;
	cursor:pointer;
	opacity:1;
	transform:scale(.6);
	transition:opacity .3s ease, transform .3s ease;
	left:-10px;
	top:-10px;
	box-shadow:0px 0px 25px rgba(0,0,0,.5);
}

.editPoint.active .beacon {
	opacity:1;
	transform:scale(.7);
}

.beacon:hover {
	opacity:1;
}

.editPoint.active .beacon:after {
	opacity:0;
	transition:opacity .3s ease, transform .3s ease;
}
.beacon:before {
	content:'';
	position:absolute;
	width:200%;
	height:200%;
}
.beacon:after {
	content:'';
	position:absolute;
	display:block;
	width:100%;
	height:100%;
	border-radius:100%;
	border:#fff solid 2px;
	margin:auto;
	animation: beacon 2s linear infinite;
	transition:opacity .2s ease;
	pointer-events: none;
}

.editPointLabel {
	position:absolute;
	font-size:11px;
	display:flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	justify-content: center;
	align-items: center;
	background:rgba(255,255,255,1);
	left:25px;
	top:-12px;
	height:22px;
	padding:2px 10px 0px;
	border-radius:3px;
	transform:scale(0);
	opacity:0;
	transform-origin: -10% 50%;
	transition:all .3s ease;
}

.editPointContainer.active  .editPointLabel {
	transform:scale(.99);
	opacity:1;
	box-shadow:0px 0px 25px rgba(0,0,0,.2);
}

.editPointLabel:before {
	content:'';
	position:absolute;
	width: 0; 
	height: 0; 
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent; 
	border-right:8px solid #FFF;
	left:-7px;
	top:6px;
	opacity:1;
}

/* EDIT POINT CONTENT */


.editPointContent {
	position:absolute;
	font-size:12px;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background:rgba(255,255,255,1);
	left:25px;
	top:-17px;
	padding:12px 15px;
	border-radius:3px;
	transform:scale(0);
	opacity:0;
	transform-origin: -7px 17px;
	transition:all .3s ease;
	pointer-events: auto;
	
/*
	transform:scale(.99);
	opacity:1;
	box-shadow:0px 0px 25px rgba(0,0,0,.2);
*/
}

.editPoint.active .editPointContent {
	transform:scale(.99);
	opacity:1;
	box-shadow:0px 0px 25px rgba(0,0,0,.2);
}

.editPointContent:before {
	content:'';
	position:absolute;
	width: 0; 
	height: 0; 
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent; 
	border-right:8px solid #FFF;
	left:-7px;
	top:11px;
	opacity:1;
}

.editPointContent:after {
	content:'';
	position:absolute;
	height:100%;
	left:-20px;
	display:block;
	width:20px;
}

.editPointTitle {
	margin-bottom:15px;
	padding-bottom:5px;
	border-bottom:#DDD solid 1px;
	white-space: nowrap;
}

@keyframes beacon {
	
    0% {
        transform:scale(.5);
    }
    50% {
        opacity:1;
    }
    100% {
        opacity:0;
        transform:scale(2.5);
    }
	
}


/* Change Background */

ul.colorSelector {
	margin:0px 0px 6px;
	padding:0px;
	display:flex;
	flex-direction: row;
}
ul.colorSelector li {
	width:30px;
	height:30px;
	border:#EEE solid 1px;
	cursor:pointer;
	list-style: none;
	margin:0px;
	padding:0px;
	border-radius:100%;
	margin-right:3px;
	box-shadow:0px 0px 0px 2px #FFF inset;
	transition:border .3s ease,transform .2s ease;
	transform:scale(0.9);
	
	border: 1px solid #FFF;
}

ul.colorSelector li.selected,
ul.colorSelector li:hover {
	border: 1px solid #628fd3;
}

ul.colorSelector li.selected {
	transform:scale(0.99)
}

/* EDITPOINT BUTTONS */

.editPointButton {
	cursor:pointer;
	padding:12px 20px 10px;
	border-radius:3px;
	border: 1px solid #999;
	flex:1;
	text-align: center;
	font-weight:600;
	box-shadow:0px 0px 0px 2px #FFF inset;
	transition:border .3s ease;
}

.editPointButton:hover {
	border: 1px solid #000;
	
}

/* EDITPOINT CLOSE */

.editClose {
	display:flex;
	justify-content: center;
	align-items: center;
	width:50px;
	height:50px;
	position:absolute;
	top:15px;
	right:15px;
	cursor:default;
	z-index:2000;
	pointer-events:auto;
	transition:transform .4s ease-out,opacity .4s ease-out,border .3s ease;
	transition-delay:.1s;
	transform: scale(0) rotate(-45deg);
	opacity:0;
	background:rgba(255,255,255,.3);
	border-radius:100%;
	border:rgba(255, 255, 255, 0.4) solid 2px;
}

.editClose:after,
.editClose:before {
	background:#FFF;
	width:70%;
	height:2px;
	position:absolute;
	display:block;
	content:'';
	cursor:pointer;
	transition:transform .4s ease-out,opacity .4s ease-out;
	transition-delay:0s;
	transform:rotate(45deg);
	border-radius:3px;
}

.editClose:before {
	transform:rotate(-45deg);
}

.editClose:hover {
	border:rgba(255, 255, 255, 1) solid 2px;
}

.editClose.active {
	opacity:1;
	transform:scale(.99) rotate(0deg);
	cursor:pointer;
}

.editClose.removeLabel {
	width:50px;
	height:50px;
	right:inherit;
	left:-400px;
	top:-55px;
	border-width: 5px;
	cursor:pointer;
	border-color:#FFF;
}

.labelContainer:not(.open) .editClose.removeLabel {
	transform:scale(1) rotate(0deg);
	opacity:1;
}

.labelContainer.close .editClose.removeLabel,
.editClose.removeLabel.hide  {
	transform:scale(0) rotate(0deg) !important;
	opacity:0 !important;
	transition:all .3s ease;
}

.editClose.removeLabel:before,
.editClose.removeLabel:after {
	height:5px;
}


/* EDITPOINT QUICK BUTTONS */

.editPointQuickButton {
	display:block;
	position:absolute;
	width: 130%;
    height: 30px;
    left: -40px;
    top: 0px;
    cursor: pointer;
}

.quickClick {
	padding:9px 15px 7px;
}

.quickClick .editPointTitle {
	padding-bottom:0px;
	border-bottom:none;
	margin-bottom:0px;
}

/* DROPZONE */

.editPoint .dropzone {
	width:100%;
	height:100%;
}

/* EDIT MODE ICON */


.theaterModeIcon .inner {
	animation: start-theaterModeIcon 0.6s cubic-bezier(.4, 1.5, .5, 1) forwards 3s;
	background-image:url("../images/view.png");
	width:75px;
	height:75px;
	background-size:100%;
	cursor:pointer;
	border-radius:100%;
	border:rgba(255, 255, 255, 0.4) solid 4px;
	box-shadow:0px 0px 60px rgba(0,0,0,.1);
	transition:border .3s ease,opacity .5s ease,transform .5s cubic-bezier(.4, 2, .5, 1);
	opacity:1;
	transform:scale(0);
	transition-delay:0s;
}

@keyframes start-theaterModeIcon {
    0% {
        transform:scale(0);
		opacity:0;
    }
    100% {
        transform:scale(.99);
		opacity:1;
    }
}

.theaterModeIcon.close .inner {
	opacity:.4;
	transform:scale(0) !important;
	transition-delay:0s;
	cursor:default;
	transition:border .3s ease,opacity .5s ease,transform .5s ease;
}

.theaterModeIcon:hover .inner {
	border:rgba(255, 255, 255, 1) solid 4px;
}

.editToggle {
	height:18px;
	width:50px;
	border:rgba(255,255,255,.5) solid 2px;
	border-radius:80px;
	background:rgba(255,255,255,.3);
	backdrop-filter:blur(30px);
	-webkit-backdrop-filter:blur(30px);
	position:absolute;
	padding:5px;
	transition:border .3s ease,transform .3s ease 0s,opacity .3s ease 0s;
	cursor:pointer;
	z-index:10;
	bottom:80px;
	opacity:0;
	transform:scale(0);
	font-size:13px;
}

.editToggle:before {
	content:"View";
	position:absolute;
	left:-38px;
	color:#FFF;
	margin-top:0px;
}

.editToggle:after {
	content:"Edit";
	position:absolute;
	right:-32px;
	color:#FFF;
	margin-top:0px;
}

.editToggle.visible {
	opacity:1;
	transform:scale(.99);
	transition:border .3s ease,transform .3s ease .7s,opacity .3s ease .7s;
}

.editToggle:hover {
	border:rgba(255,255,255,1) solid 2px;
}

.editToggle div {
	position:absolute;
	height:20px;
	width:20px;
	border-radius:100%;
	background:rgba(255,255,255,.6);
	transition:left .3s ease,background .3s ease;
	border:#FFF solid 2px;
	left:2px;
	top:2px;
}

.editToggle.on div {
	left:calc(100% - 26px);
	background:rgba(255,255,255,1);
}

.editToggle:hover div {
/* 	background:rgba(255,255,255,1); */
}

